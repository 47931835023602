import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import moment from 'moment';
import EditIcon from "@material-ui/icons/Edit";

const useStyles = makeStyles((theme) => ({
    inline: {
        width: '100%'
    }
}));

export default function ContactNotesDialogListItem(props) {
    const { note, deleteItem, editItem } = props;
    const classes = useStyles();

    const handleDelete = (item) => {
        deleteItem(item);
    }

    const handleEdit = (item) => {
        editItem(item);
    }
    return (
        <ListItem alignItems="flex-start">
            <ListItemText
                primary={
                    <>
            <Typography
                component="span"
                variant="body2"
                className={classes.inline}
                color="textPrimary"
                style={{ fontSize: '12px' }}  // Tamanho da fonte para o texto principal
            >
                {note.note}
            </Typography>
                    </>
                }
                secondary={
        <Typography
            component="span"
            variant="body2"
            color="textSecondary"
            style={{ fontSize: '10px' }}  // Tamanho da fonte para o texto secundário (usuário e data)
        >
            {note.user.name}, {moment(note.createdAt).format('DD/MM/YY HH:mm')}
        </Typography>
                }
            />
            <ListItemSecondaryAction style={{ display: 'flex', flexDirection: 'column' }}>
                {/* <IconButton onClick={() => handleEdit(note)} edge="end" aria-label="edit">
                    <EditIcon />
                </IconButton> */}
                <IconButton onClick={() => handleDelete(note)} edge="end" aria-label="delete">
                    <DeleteIcon />
                </IconButton>
            </ListItemSecondaryAction>

        </ListItem>
    )
}

ContactNotesDialogListItem.propTypes = {
    note: PropTypes.object.isRequired,
    deleteItem: PropTypes.func.isRequired
}